import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import Layout from '@components/Layout/Layout';
import Home from '@components/Home/Home';

const SEO = loadable(() => import('@components/seo'));
const About = loadable(() => import('@components/About/About'));
const Skills = loadable(() => import('@components/Skills/Skills'));
const Projects = loadable(() => import('@components/Projects/Projects'));
const JsProjects = loadable(() =>
  import('@src/components/Projects/JsProjects')
);
const SmallProjects = loadable(() =>
  import('@src/components/Projects/SmallProjects')
);
const Contact = loadable(() => import('@components/Contact/Contact'));
const Designs = loadable(() => import('@src/components/Designs/Designs'));
// loadable(() => import('typeface-nunito'));
const Speed = loadable(() => import('../components/Layout/Speed'));

const IndexPage = () => {
  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      function(e) {
        // do something here...
        e.preventDefault();
      },
      false
    );
  }, []);

  return (
    <Layout>
      <SEO title="Aman Seelay" />

      <Home />
      <About />
      <Skills />
      <Projects />
      <JsProjects />
      <SmallProjects />
      <Designs />
      <Contact />
      <Speed />
    </Layout>
  );
};

export default IndexPage;
